import {
  Container,
  ContainerProps,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import * as React from "react";

export function OffsetContainer(props: ContainerProps): React.ReactElement {
  const classes = useStyles();

  return <Container {...props} className={classes.root} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(8),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(11),
      },
    },
  })
);
