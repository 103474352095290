import * as React from "react";

import { AppBar } from "./app-bar";
import { OffsetContainer } from "./offset-container";
import { PageTitle } from "./page-title";

interface PageProps {
  title: string;
  showSearch?: boolean;
  children: NonNullable<React.ReactNode>;
}

export function Page(props: PageProps): React.ReactElement {
  return (
    <>
      <PageTitle title={props.title} />
      <AppBar title={props.title} showSearch={props.showSearch || false} />
      <OffsetContainer maxWidth="xl">{props.children}</OffsetContainer>
    </>
  );
}
