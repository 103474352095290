import {
  enUS as muiEN,
  Localization,
  ruRU as muiRU,
} from "@material-ui/core/locale";
import { format, formatDistance, Locale, parse, parseISO } from "date-fns";
import { enUS as dfEN, ru as dfRU } from "date-fns/locale";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function sleep(t: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), t);
  });
}

export function parseDate(s: string): Date {
  return parse(s, "yyyy-MM-dd", new Date());
}

export function formatDate(dt: string | Date | null): string {
  if (dt === null) {
    return "";
  }
  if (typeof dt === "string") {
    dt = parseDate(dt);
  }
  return format(dt, "dd LLL yy").toUpperCase();
}

export function useDateFnsLocale(): Locale {
  const { i18n } = useTranslation();
  if (i18n.language === "ru") {
    return dfRU;
  }
  return dfEN;
}

export function useMaterialUILocale(): Localization {
  const { i18n } = useTranslation();
  if (i18n.language === "ru") {
    return muiRU;
  }
  return muiEN;
}

export function useTimeSince(): (dt: string | Date | null) => string | null {
  const locale = useDateFnsLocale();
  const { t } = useTranslation("common");
  const func = useCallback(
    (dt: string | Date | null): string | null => {
      if (!dt) {
        return null;
      }
      return (
        formatDistance(typeof dt === "string" ? parseISO(dt) : dt, new Date(), {
          locale,
        }) +
        " " +
        t("ago")
      );
    },
    [locale, t]
  );

  return func;
}
