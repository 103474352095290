//Adapted from https://material-ui.com/guides/composition/#link

import { ListItem, ListItemProps } from "@material-ui/core";
import { Omit } from "@material-ui/types";
import * as React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

interface ListItemLinkProps extends ListItemProps {
  to: string;
}

export function ListItemLink({
  to,
  children,
}: ListItemLinkProps): React.ReactElement {
  const renderLink = React.useMemo(() => {
    // eslint-disable-next-line
    const link = React.forwardRef<any, Omit<RouterLinkProps, "to">>(
      (itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />
    );
    link.displayName = "renderLink";
    return link;
  }, [to]);

  return (
    <ListItem component={renderLink} button={true}>
      {children}
    </ListItem>
  );
}
