import { IconButton, IconButtonProps } from "@material-ui/core";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkIconButtonProps extends IconButtonProps {
  href?: string;
}

export function LinkIconButton(props: LinkIconButtonProps): React.ReactElement {
  return (
    <IconButton
      {...{ ...props, ...{ component: RouterLink, to: props.href } }}
    />
  );
}
