import { Auth0Provider } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

export function Auth0ProviderWithHistory({
  children,
}: Auth0ProviderWithHistoryProps): React.ReactElement {
  const history = useHistory();

  function onRedirectCallback(appState: AppState) {
    history.push(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ""}
      audience={process.env.REACT_APP_AUTH_AUDIENCE || ""}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
