import { withAuthenticationRequired } from "@auth0/auth0-react";
import * as React from "react";
import { Route, RouteProps } from "react-router-dom";

import { LoadingSpinner } from "../components/common";

type ProtectedRouteProps = RouteProps & {
  component: React.ComponentType;
};

const onRedirecting = () => <LoadingSpinner />;

export function ProtectedRoute({
  component,
  ...args
}: ProtectedRouteProps): React.ReactElement {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting,
      })}
      {...args}
    />
  );
}
