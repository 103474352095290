import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";

const copyright = `© Copyright ${new Date().getFullYear()}, Anton Kravchenko`;

export function Footer(): React.ReactElement {
  const classes = useStyles();

  return (
    <footer data-test="copyright-footer" className={classes.root}>
      <Typography variant="body2" align="center">
        {copyright}
      </Typography>
    </footer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "auto",
      width: "100%",
      padding: theme.spacing(1),
    },
  })
);
