import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import * as React from "react";

import { Footer } from "./layout";
import { Router } from "./router";
import { darkTheme, lightTheme } from "./themes";
import { useMaterialUILocale } from "./utils";

export function App(): React.ReactElement {
  const locale = useMaterialUILocale();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () => createMuiTheme(prefersDarkMode ? darkTheme : lightTheme, locale),
    [prefersDarkMode, locale]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
      <Footer />
    </ThemeProvider>
  );
}
