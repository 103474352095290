import {
  createStyles,
  makeStyles,
  TableCell,
  TableCellProps,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";

export interface NumberTableCellProps extends TableCellProps {
  value: number | null | undefined;
  format?: "currency" | "percent";
  highlight?: boolean;
  round?: boolean;
  tooltip?: string | null;
}

function formatCurrency(value: number, decimalPlaces: number): string {
  return (value < 0 ? "-" : "") + "$" + Math.abs(value).toFixed(decimalPlaces);
}

function formatPercent(value: number): string {
  return (value * 100).toFixed(2) + "%";
}

export function NumberTableCell({
  format,
  value,
  highlight,
  round,
  tooltip,
  ...rest
}: NumberTableCellProps): React.ReactElement {
  const classes = useStyles();
  const decimalPlaces = round ? 0 : 2;

  let formattedValue: string;
  if (value === null || value === undefined) {
    formattedValue = "";
  } else {
    switch (format) {
      case "currency":
        formattedValue = formatCurrency(value, decimalPlaces);
        break;
      case "percent":
        formattedValue = formatPercent(value);
        break;
      default:
        formattedValue = value.toFixed(decimalPlaces);
        break;
    }
  }

  let colorClass: string | undefined;
  if (!highlight || value === null || value === undefined || value === 0) {
    colorClass = undefined;
  } else if (value < 0) {
    colorClass = classes.negative;
  } else if (value > 0) {
    colorClass = classes.positive;
  }

  return (
    <TableCell
      {...{
        ...rest,
        ...{ className: colorClass, align: "right" },
      }}
    >
      {tooltip ? (
        <Tooltip title={tooltip}>
          <div>{formattedValue}</div>
        </Tooltip>
      ) : (
        formattedValue
      )}
    </TableCell>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    positive: {
      backgroundColor: "#b7e1cd",
      color: "#38761d",
    },
    negative: {
      backgroundColor: "#f4c7c3",
      color: "#990000",
    },
  })
);
