import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";

// import { sleep } from "../utils";

const cache = new InMemoryCache();
const httpProtocol = process.env.NODE_ENV === "production" ? "https" : "http";
const httpLink = createHttpLink({
  uri: `${httpProtocol}://${process.env.REACT_APP_API_DOMAIN}/v1/graphql`,
});

interface AuthorizedApolloProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export function AuthorizedApolloProvider(
  props: AuthorizedApolloProviderProps
): React.ReactElement {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    // await sleep(2000); // TODO Remove later
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    connectToDevTools: process.env.NODE_ENV === "development",
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
