import * as React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
}

export function PageTitle({ title }: Props): React.ReactElement {
  return (
    <Helmet>
      <title>{title} - My Trading Tools</title>
    </Helmet>
  );
}
