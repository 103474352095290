import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

interface Language {
  code: string;
  name: string;
}

export const languages: Language[] = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "ru",
    name: "Русский",
  },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    resources: {
      en: {
        pages: {
          myPortfolio: "My Portfolio",
          myLists: "My lists",
        },
        homepage: {
          subtitle: "Modern tools for trading",
          login: "Login",
        },
        navigation: {
          searchSymbol: "Symbol",
          language: "Language",
          logout: "Logout",
        },
        portfolioSummary: {
          symbol: "Symbol",
          price: "Price",
          expenses: "Expenses",
          income: "Income",
          roi: "%",
          days: "Days",
          annualRoi: "Annual %",
          put: "Put",
          stocks: "Stocks",
          hedging: "Hedging",
          call: "Call",
        },
        portfolioReportsTable: {
          title: "Flex Reports",
          name: "Name",
          lastUpdate: "Last Update",
        },
        portfolioTradesTable: {
          noTradesMessage: "No trades yet",
          date: "Date",
          expiration: "Expiration",
          strike: "Strike",
          quantity: "Qty",
          action: "Action",
          received: "Received",
          payed: "Payed",
          commission: "Commission",
          result: "Result",
          provision: "Provision",
        },
        common: {
          ago: "ago",
          add: "Add",
          cancel: "Cancel",
        },
      },
      ru: {
        pages: {
          myPortfolio: "Мой портфель",
          myLists: "Мои списки",
        },
        homepage: {
          subtitle: "Современные инструменты для торговли",
          login: "Войти",
        },
        navigation: {
          searchSymbol: "Тикер",
          language: "Язык",
          logout: "Выйти",
        },
        portfolioSummary: {
          symbol: "Тикер",
          price: "Цена",
          expenses: "Расходы",
          income: "Приходы",
          roi: "%",
          days: "Дней",
          annualRoi: "Годовой %",
          put: "Put",
          stocks: "Акции",
          hedging: "Хеджирование",
          call: "Call",
        },
        portfolioReportsTable: {
          title: "Flex отчеты",
          name: "Название",
          lastUpdate: "Последнее обновление",
        },
        portfolioTradesTable: {
          noTradesMessage: "Пока нет сделок",
          date: "Дата",
          expiration: "Экспирация",
          strike: "Страйк",
          quantity: "Кол-во",
          action: "Действие",
          received: "Получено",
          payed: "Заплачено",
          commission: "Комиссия",
          result: "Результат",
          provision: "Обеспечение",
        },
        common: {
          ago: "назад",
          add: "Добавить",
          cancel: "Отменить",
        },
      },
    },
  });

export default i18n;
