import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TranslateIcon from "@material-ui/icons/Translate";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { languages } from "../../i18n";

export function LanguagesList(): React.ReactElement {
  const classes = useStyles();
  const { t, i18n } = useTranslation("navigation");
  const [opened, setOpened] = React.useState(false);

  return (
    <React.Fragment>
      <ListItem button onClick={() => setOpened(!opened)}>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary={t("language")} />
        {opened ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={opened} unmountOnExit>
        <List component="div" disablePadding>
          {languages.map((language) => (
            <ListItem
              key={language.code}
              className={classes.nested}
              button
              onClick={() => i18n.changeLanguage(language.code)}
            >
              <ListItemText primary={language.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);
