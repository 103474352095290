import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import yellow from "@material-ui/core/colors/yellow";
import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface CommonColors {
    undervalued: string;
    neutral: string;
    overvalued: string;
  }
}

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    common: {
      undervalued: green[200],
      neutral: yellow[200],
      overvalued: deepOrange[200],
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: blue[200],
    },
    secondary: {
      main: pink[200],
    },
    common: {
      undervalued: green[100],
      neutral: yellow[100],
      overvalued: deepOrange[100],
    },
  },
});
