import {
  AppBar as MaterialAppBar,
  createStyles,
  fade,
  IconButton,
  InputBase,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { Drawer } from "./drawer";

interface AppBarProps {
  title: string;
  showSearch: boolean;
}

export function AppBar(props: AppBarProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation("navigation");
  const [isDrawerOpened, setDrawerOpened] = React.useState(false);

  return (
    <React.Fragment>
      <MaterialAppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpened(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {props.title}
          </Typography>
          {props.showSearch && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={`${t("searchSymbol")}…`}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          )}
        </Toolbar>
      </MaterialAppBar>
      <Drawer opened={isDrawerOpened} setOpened={setDrawerOpened} />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(
        theme.palette.type === "light"
          ? theme.palette.common.white
          : theme.palette.common.black,
        0.15
      ),
      "&:hover": {
        backgroundColor: fade(
          theme.palette.type === "light"
            ? theme.palette.common.white
            : theme.palette.common.black,
          0.25
        ),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);
