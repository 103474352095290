import { useAuth0 } from "@auth0/auth0-react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { ListItemLink } from "../../components/common";
import { LanguagesList } from "./languages-list";

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

interface DrawerProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export function Drawer({ opened, setOpened }: DrawerProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation(["navigation", "pages"]);
  const { logout } = useAuth0();

  return (
    <SwipeableDrawer
      anchor="left"
      open={opened}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
    >
      <List component="nav" className={classes.list}>
        <ListItemLink to="/portfolio">
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText>{t("pages:myPortfolio")}</ListItemText>
        </ListItemLink>
      </List>
      <Divider />
      <List component="nav" className={classes.list}>
        <LanguagesList />
        <ListItem
          button
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText>{t("logout")}</ListItemText>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
}

const useStyles = makeStyles({
  list: {
    width: "250px",
  },
});
