import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Auth0ProviderWithHistory, ProtectedRoute } from "./auth";
import { AuthorizedApolloProvider } from "./backend";
import { LoadingSpinner } from "./components/common";

const LandingPage = React.lazy(() => import("./pages/landing-page"));
const PortfolioPage = React.lazy(() => import("./pages/portfolio-page"));
const NotFoundPage = React.lazy(() => import("./pages/not-found-page"));
const PortfolioSymbolPage = React.lazy(
  () => import("./pages/portfolio-symbol")
);

export function Router(): React.ReactElement {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>
          <React.Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <ProtectedRoute
                path="/portfolio/:accountId/:symbol"
                exact
                component={PortfolioSymbolPage}
              />
              <ProtectedRoute
                path="/portfolio"
                exact
                component={PortfolioPage}
              />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </React.Suspense>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}
