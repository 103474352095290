import {
  Breadcrumbs as MuiBreadcrumbs,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";

import { Link } from "./link";

interface BreadcrumbsProps {
  crumbs: {
    text: string;
    href?: string;
  }[];
}

export function Breadcrumbs(props: BreadcrumbsProps): React.ReactElement {
  const classes = useStyles();

  if (!props.crumbs.length) {
    return <></>;
  }

  const lastCrumb = props.crumbs[props.crumbs.length - 1];
  return (
    <MuiBreadcrumbs className={classes.root}>
      {props.crumbs.slice(0, -1).map((crumb) =>
        crumb.href ? (
          <Link key={crumb.text} color="inherit" href={crumb.href}>
            {crumb.text}
          </Link>
        ) : (
          <Typography key={crumb.text} color="inherit">
            {crumb.text}
          </Typography>
        )
      )}
      <Typography color="textPrimary">{lastCrumb.text}</Typography>
    </MuiBreadcrumbs>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
  })
);
