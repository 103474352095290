import "./index.css";
import "./i18n";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./app";
import { reportWebVitals } from "./report-web-vitals";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
