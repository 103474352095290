import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import * as React from "react";

export function LoadingSpinner(): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" size="4em" />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      padding: theme.spacing(2),
    },
  })
);
